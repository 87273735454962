
import React, {useContext, useRef, useEffect, useState} from  'react';
import {Link} from 'react-router-dom'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import ChatIcon from '@mui/icons-material/Chat';

// import SearchIcon from '@material-ui/icons/Search';
import GetAppIcon from '@mui/icons-material/GetApp';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LanguageIcon from '@mui/icons-material/Language';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import Footer from "./Footer"
import Nav from "./Nav"




export default function Dash_std() {
  return (
    <div>     
    {/* Nav */}
    <Nav/>
    {/* end Nav */}
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-gray-900">Welcome To RIG.jr</h1>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          {/* Replace with your content */}
          {/* Avator of the User */}
            <a href="#" class="block relative">
                <img alt="profil"                                 src="https://images.unsplash.com/photo-1507591064344-4c6ce005b128?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"
 class="mx-auto object-cover rounded-full h-40 w-40 "/>
            </a>
           {/*Avator ends */}
           {/* Features Regarding studing*/}
           <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold text-gray-900 text-center">Lets Start Learning</h1>
           </div>

           

            <div class="grid md:grid-cols-3 grid-cols-1 gap-4"> 


            {/* <div class="relative w-full p-4 overflow-hidden bg-white shadow-lg rounded-xl md:w-80 ">
        <p class="text-xl text-center font-medium text-gray-800  mb-2">
            Coaching
        </p>
     
        <Link to='/chat'>

    <div class="flex items-center justify-between p-3 mb-2 bg-blue-100 rounded">
        <span class="p-2 bg-white rounded-lg">
        <svg class="h-8 w-8 text-blue-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M4 21v-13a3 3 0 0 1 3 -3h10a3 3 0 0 1 3 3v6a3 3 0 0 1 -3 3h-9l-4 4" />  <line x1="8" y1="9" x2="16" y2="9" />  <line x1="8" y1="13" x2="14" y2="13" /></svg>             
        </span>
        <div class="flex items-center justify-between w-full ml-2">
            <p>
                1:1 With Teacher
            </p>
           
        </div>
    </div>
    </Link>

    <Link to='/subject'>                   

    <div class="flex items-center justify-between p-3 mb-2 bg-blue-100 rounded">
        <span class="p-2 bg-white rounded-lg">
        <svg class="h-8 w-8 text-blue-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <line x1="10" y1="14" x2="21" y2="3" />  <path d="M21 3L14.5 21a.55 .55 0 0 1 -1 0L10 14L3 10.5a.55 .55 0 0 1 0 -1L21 3" /></svg>        </span>
        <div class="flex items-center justify-between w-full ml-2">
            <p>
                Post Doubts
            </p>
           
        </div>
    </div>
    </Link>


    <Link to="/searchTeacher">

    <div class="flex items-center justify-between p-3 mb-2 bg-blue-100 rounded">
        <span class="p-2 bg-white rounded-lg">
        <svg class="h-8 w-8 text-blue-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"/>
</svg>

        </span>
        <div class="flex items-center justify-between w-full ml-2">
            <p>
                Teachers
            </p>
           
        </div>
    </div>
    </Link>


</div> */}
            <div class="relative w-full p-4 overflow-hidden bg-white shadow-lg rounded-xl md:w-80 ">
        <p class="text-xl text-center font-medium text-gray-800  mb-2">
            Up Skill
        </p>
     
     <Link to={"/coursesessions"}>
    <div class="flex items-center justify-between p-3 mb-2 bg-violet-100 rounded">
        <span class="p-2 bg-white rounded-lg">
            <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M1520 1216q0-40-28-68l-208-208q-28-28-68-28-42 0-72 32 3 3 19 18.5t21.5 21.5 15 19 13 25.5 3.5 27.5q0 40-28 68t-68 28q-15 0-27.5-3.5t-25.5-13-19-15-21.5-21.5-18.5-19q-33 31-33 73 0 40 28 68l206 207q27 27 68 27 40 0 68-26l147-146q28-28 28-67zm-703-705q0-40-28-68l-206-207q-28-28-68-28-39 0-68 27l-147 146q-28 28-28 67 0 40 28 68l208 208q27 27 68 27 42 0 72-31-3-3-19-18.5t-21.5-21.5-15-19-13-25.5-3.5-27.5q0-40 28-68t68-28q15 0 27.5 3.5t25.5 13 19 15 21.5 21.5 18.5 19q33-31 33-73zm895 705q0 120-85 203l-147 146q-83 83-203 83-121 0-204-85l-206-207q-83-83-83-203 0-123 88-209l-88-88q-86 88-208 88-120 0-204-84l-208-208q-84-84-84-204t85-203l147-146q83-83 203-83 121 0 204 85l206 207q83 83 83 203 0 123-88 209l88 88q86-88 208-88 120 0 204 84l208 208q84 84 84 204z">
                </path>
            </svg>
        </span>
        <div class="flex items-center justify-between w-full ml-2">
            <p>
            Search Courses 
            </p>
           
        </div>
    </div>
    </Link>
    <Link to={"/mycoursesessions"}>

    <div class="flex items-center justify-between p-3 mb-2 bg-violet-100 rounded">
        <span class="p-2 bg-white rounded-lg">
            <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M1596 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28h-1344q-40 0-68-28t-28-68v-1600q0-40 28-68t68-28h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528v-1024h-416q-40 0-68-28t-28-68v-416h-768v1536h1280zm-128-448v320h-1024v-192l192-192 128 128 384-384zm-832-192q-80 0-136-56t-56-136 56-136 136-56 136 56 56 136-56 136-136 56z">
                </path>
            </svg>
        </span>
        <div class="flex items-center justify-between w-full ml-2">
            <p>
                My Courses 
            </p>
          
        </div>
    </div>

    </Link>
    {/* <div class="flex items-center justify-between p-3 mb-2 bg-yellow-100 rounded">
        <span class="p-2 bg-white rounded-lg">
            <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M960 256q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm832 928v352q0 22-20 30-8 2-12 2-12 0-23-9l-93-93q-119 143-318.5 226.5t-429.5 83.5-429.5-83.5-318.5-226.5l-93 93q-9 9-23 9-4 0-12-2-20-8-20-30v-352q0-14 9-23t23-9h352q22 0 30 20 8 19-7 35l-100 100q67 91 189.5 153.5t271.5 82.5v-647h-192q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h192v-163q-58-34-93-92.5t-35-128.5q0-106 75-181t181-75 181 75 75 181q0 70-35 128.5t-93 92.5v163h192q26 0 45 19t19 45v128q0 26-19 45t-45 19h-192v647q149-20 271.5-82.5t189.5-153.5l-100-100q-15-16-7-35 8-20 30-20h352q14 0 23 9t9 23z">
                </path>
            </svg>
        </span>
        <div class="flex items-center justify-between w-full ml-2">
            <p>
                Helfest tickets
            </p>
        
        </div>
    </div> */}
</div>
            <div class="relative w-full p-4 overflow-hidden bg-white shadow-lg rounded-xl md:w-80 ">
        <p class="text-xl text-center font-medium text-gray-800  mb-2">
            Dzongkha Session
        </p>
     
     <Link to={"/dzongkhasessions"}>
    <div class="flex items-center justify-between p-3 mb-2 bg-violet-100 rounded">
        <span class="p-2 bg-white rounded-lg">
            <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M1520 1216q0-40-28-68l-208-208q-28-28-68-28-42 0-72 32 3 3 19 18.5t21.5 21.5 15 19 13 25.5 3.5 27.5q0 40-28 68t-68 28q-15 0-27.5-3.5t-25.5-13-19-15-21.5-21.5-18.5-19q-33 31-33 73 0 40 28 68l206 207q27 27 68 27 40 0 68-26l147-146q28-28 28-67zm-703-705q0-40-28-68l-206-207q-28-28-68-28-39 0-68 27l-147 146q-28 28-28 67 0 40 28 68l208 208q27 27 68 27 42 0 72-31-3-3-19-18.5t-21.5-21.5-15-19-13-25.5-3.5-27.5q0-40 28-68t68-28q15 0 27.5 3.5t25.5 13 19 15 21.5 21.5 18.5 19q33-31 33-73zm895 705q0 120-85 203l-147 146q-83 83-203 83-121 0-204-85l-206-207q-83-83-83-203 0-123 88-209l-88-88q-86 88-208 88-120 0-204-84l-208-208q-84-84-84-204t85-203l147-146q83-83 203-83 121 0 204 85l206 207q83 83 83 203 0 123-88 209l88 88q86-88 208-88 120 0 204 84l208 208q84 84 84 204z">
                </path>
            </svg>
        </span>
        <div class="flex items-center justify-between w-full ml-2">
            <p>
            Search Dzongkha Sessions 
            </p>
           
        </div>
    </div>
    </Link>
    <Link to={"/mydzongkhasessions"}>

    <div class="flex items-center justify-between p-3 mb-2 bg-violet-100 rounded">
        <span class="p-2 bg-white rounded-lg">
            <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M1596 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28h-1344q-40 0-68-28t-28-68v-1600q0-40 28-68t68-28h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528v-1024h-416q-40 0-68-28t-28-68v-416h-768v1536h1280zm-128-448v320h-1024v-192l192-192 128 128 384-384zm-832-192q-80 0-136-56t-56-136 56-136 136-56 136 56 56 136-56 136-136 56z">
                </path>
            </svg>
        </span>
        <div class="flex items-center justify-between w-full ml-2">
            <p>
                My Dzongkha Sessions 
            </p>
          
        </div>
    </div>

    </Link>
    {/* <div class="flex items-center justify-between p-3 mb-2 bg-yellow-100 rounded">
        <span class="p-2 bg-white rounded-lg">
            <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M960 256q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm832 928v352q0 22-20 30-8 2-12 2-12 0-23-9l-93-93q-119 143-318.5 226.5t-429.5 83.5-429.5-83.5-318.5-226.5l-93 93q-9 9-23 9-4 0-12-2-20-8-20-30v-352q0-14 9-23t23-9h352q22 0 30 20 8 19-7 35l-100 100q67 91 189.5 153.5t271.5 82.5v-647h-192q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h192v-163q-58-34-93-92.5t-35-128.5q0-106 75-181t181-75 181 75 75 181q0 70-35 128.5t-93 92.5v163h192q26 0 45 19t19 45v128q0 26-19 45t-45 19h-192v647q149-20 271.5-82.5t189.5-153.5l-100-100q-15-16-7-35 8-20 30-20h352q14 0 23 9t9 23z">
                </path>
            </svg>
        </span>
        <div class="flex items-center justify-between w-full ml-2">
            <p>
                Helfest tickets
            </p>
        
        </div>
    </div> */}
</div>
<div class="relative w-full p-4 overflow-hidden bg-white shadow-lg rounded-xl md:w-80 ">
<p class="text-xl text-center font-medium text-gray-800  mb-2">
Rig Jr Academy
</p>

<h1 class="uppercase text-center mt-24 text-3xl"> Coming Soon </h1>
</div>



            </div>
         
            {/* <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold text-gray-900 text-center">Additional Resources</h1>
           </div>

           <div class="sm:flex flex-wrap justify-center items-center text-center gap-8 ">

           <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 px-4 py-4 bg-white mt-6  shadow-lg rounded-lg ">
                   
                               <a href="https://www.bcsea.bt/questions-download" target='_blank'>

                    <div class="flex-shrink-0">
                        <div class="flex items-center mx-auto justify-center h-30 w-30 rounded-md">
                            <GetAppIcon style={{ color: "skyblue", width:"40px",height:"40px"}}/>
                        </div>
                    </div>
                    <h3 class="text-2xl sm:text-xl text-gray-700 font-semibold  py-4">
                        Download Past Papers
                    </h3>
                                    </a>

                </div>

                <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 px-4 py-4 bg-white mt-6  shadow-lg rounded-lg ">
                    
                    <Link to='/linkpage'>



                  
                    
                    <div class="flex-shrink-0">
                        <div class="flex items-center mx-auto justify-center h-12 w-12 rounded-md ">
                            < LanguageIcon style={{ color: "skyblue", width:"40px",height:"40px"}}/>
                        </div>
                    </div>
                    <h3 class="text-2xl sm:text-xl text-gray-700 font-semibold  py-4">
                        Visit Education Websites
                    </h3>
                    </Link>
                </div>


                <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 px-4 py-4 bg-white mt-6  shadow-lg rounded-lg ">
                <Link to='/scholarships'>

                    <div class="flex-shrink-0">
                        <div class="flex items-center mx-auto justify-center h-12 w-12 rounded-md ">
                            < LocationOnOutlinedIcon style={{ color: "skyblue", width:"40px",height:"40px"}}/>
                        </div>
                    </div>
                    <h3 class="text-2xl sm:text-xl text-gray-700 font-semibold  py-4">
                        See Office Location
                    </h3>
                    </Link>

                </div>
            </div>
            */}
        </div>
      </main>
       {/* Footer */}
       <Footer/>
       {/* end Footer */}
    </div>
  )
}
