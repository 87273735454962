import React, {useContext, useRef, useEffect, useState} from  'react';
import {Link, useNavigate} from 'react-router-dom'
// import DeleteIcon from '@mui/icons-material/Delete';
import DeleteIcon from '@mui/icons-material/Delete';
import {UserContex} from '../App'

import Nav from "./Nav"
import Footer from "./Footer"
import BookMarkedList from './BookMarkedList'

const people = [
    {
      name: 'Jane Cooper',
      title: 'Regional Paradigm Technician',
      department: 'Optimization',
      role: 'Admin',
      email: 'jane.cooper@example.com',
      image:
        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
    },
  ]

  

function MyCourseSessions() {

  const [search,setSearch] = useState('')
  const [userDetails, setUserDetails] = useState("")
  const history = useNavigate()
  const {state, dispatch}= useContext(UserContex)


  console.log(userDetails)

  useEffect(()=>{

      fetch('/getMyCoursesessions',{
        headers:{
            Authorization: "Bearer " +localStorage.getItem("jwt")
            
        }
    }).then(res=>res.json())
    .then(result=>{
      
        setUserDetails(result.posts)
        
    })
  
  
    },[])


    const deletePost = (postId)=>{

        fetch(`/deletemyCoursesubscription`,{
            method:"delete",
      headers:{
          "Content-Type":"application/json",
          Authorization: "Bearer " +localStorage.getItem("jwt")

      },
      body: JSON.stringify({
       sessionid: postId
        
      })
        }).then(res=>res.json())
        .then(result=>{
            const newData = userDetails.filter(item=>{
                return item._id !== result._id
            })
            setUserDetails(newData)
        }).catch(err=>{
            console.log(err)
        })
    }
  
    return (
        <div>
         {/* Nav */}
        <Nav/>
        {/* end Nav */}
        <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-gray-900">Your Enrolled Courses</h1>
        </div>
      </header>

      {/* <div className="flex items-center justify-center min-h-screen bg-gray-100">

<img src={require("../assets/img/StartingPage_icons.png")} className="h-full w-full" />
</div> */}


      {
        userDetails?
        userDetails.length>0?
        
        
        <main >
      <div className="flex flex-col items-center w-full bg-gray-100">
      
      {userDetails.map(item=>{

          return(
            <>
        



        
        <div class="shadow-lg rounded-2xl w-2/3 my-4 bg-white h-32 text-center">

        
     <Link to={`/prejoiningstd/${item._id}`}>
    <div class="px-8 py-8 flex-row gap-20 flex justify-between my-2 ">


               
                
             

        <div class="flex-shrink-0">
            <a href="" class="block relative">
                <img alt="profil" src="https://images.unsplash.com/photo-1573496799652-408c2ac9fe98?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80" class="mx-auto object-cover rounded-full h-16 w-16 "/>
            </a>
        </div>
        <div class=" flex flex-col">
            <span class="text-gray-600  text-lg font-medium">
            {item.title}
            </span>
            <span class="text-gray-400 text-xs">
            {item.body}
            </span>
        </div>
        {/* <div class=" flex flex-col">
            <span class="text-gray-600  text-lg font-medium">
                Topic
            </span>
            <span class="text-gray-400 text-xs">
                Subject
            </span>
        </div> */}
        <svg class="h-8 w-8 text-purple-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <polygon points="23 7 16 12 23 17 23 7" />  <rect x="1" y="5" width="15" height="14" rx="2" ry="2" /></svg>
        {/* <DeleteIcon style={{   color: "red", width:"40px",height:"40px"}} 
        
        onClick={()=>{if(window.confirm('Are you sure you want to delete?')) deletePost(item._id)}}
        />       */}

    </div>

            </Link>



    
</div>


                    
                    </>
          )


      })}
        



      
         

           
          
           
       
      </div>

      </main>
      :<>
      
      <div className="flex items-center justify-center min-h-screen bg-gray-100">

<img src={require("../assets/img/StartingPage_iconsy.png")} className="h-1/2 w-1/2" />
</div>
      </>
      
      :
      <>
      
     Loading ...
      </>

      }
      
        {/* Footer */}
        <Footer/>
        {/* end Footer */}    
        </div>
    )
}

export default MyCourseSessions

  
